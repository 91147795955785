import store from '/src/store';
import {
    ApiGetCitys,
    ApiGetIndustry,
    ApiGetIndustryNew,
} from '/src/api/common';
import { ApiOrderGetVipConfig } from '/src/api/order';
// import { ApiExportGetExportConfig } from '/src/api/export';

export function getCitys(cb) {
    const { staticData } = store.getters;

    if (staticData.citys) {
        cb && cb(staticData.citys);

        return false;
    }

    ApiGetCitys()
        .then(res => {
            store
                .dispatch('SetStaticData', {
                    key: 'citys',
                    data: res.data,
                })
                .then(() => {
                    cb && cb(res.data);
                })
                .catch(() => {});
        })
        .catch(() => {
            cb && cb();
        });
}

export function getCitysMap(cb) {
    const tmpCitysMap = {};

    getCitys(data => {
        data.forEach(item => {
            tmpCitysMap[item.code] = item.name;

            if (item.citys && item.citys.length) {
                item.citys.forEach(citem => {
                    tmpCitysMap[citem.code] = citem.name;
                });
            }
        });

        cb && cb(tmpCitysMap);
    });
}

export function getIndustry(cb) {
    const { staticData } = store.getters;

    if (staticData.industry) {
        cb && cb(staticData.industry);

        return false;
    }

    // const tmpMockData = [
    //     { code: 'I01', name: '权那' },
    //     { code: 'I11', name: '眼七理安' },
    //     { code: 'I21', name: '对只算属' },
    //     { code: 'I31', name: '法形' },
    //     { code: 'I41', name: '造感持' },
    //     { code: 'I51', name: '重深没' },
    //     { code: 'I61', name: '好基比况物' },
    //     { code: 'I71', name: '关候铁二' },
    //     { code: 'I81', name: '别经之' },
    //     { code: 'I91', name: '清持' },
    // ];

    // store
    //     .dispatch('SetStaticData', {
    //         key: 'industry',
    //         data: tmpMockData,
    //     })
    //     .then(() => {
    //         cb && cb(tmpMockData);
    //     })
    //     .catch(() => {});

    ApiGetIndustry()
        .then(res => {
            store
                .dispatch('SetStaticData', {
                    key: 'industry',
                    data: res.data,
                })
                .then(() => {
                    cb && cb(res.data);
                })
                .catch(() => {});
        })
        .catch(() => {
            cb && cb();
        });
}

export function getIndustryMap(cb) {
    const tmpIndustryMap = {};

    getIndustry(data => {
        data.forEach(item => {
            if (item.children && item.children.length) {
                item.children.forEach(citem => {
                    tmpIndustryMap[citem.code] = citem.name;
                });
            }

            tmpIndustryMap[item.code] = item.name;
        });

        cb && cb(tmpIndustryMap);
    });
}

export function getIndustryNew(cb) {
    const { staticData } = store.getters;

    if (staticData.industryNew) {
        cb && cb(staticData.industryNew);

        return false;
    }

    ApiGetIndustryNew()
        .then(res => {
            store
                .dispatch('SetStaticData', {
                    key: 'industryNew',
                    data: res.data,
                })
                .then(() => {
                    cb && cb(res.data);
                })
                .catch(() => {});
        })
        .catch(() => {
            cb && cb();
        });
}

export function getStatic() {
    const tmpUpdateTime = [];
    let tmpYear = new Date().getFullYear();

    for (let i = 0; i < 5; i++) {
        tmpUpdateTime.push({ label: tmpYear, value: i + '' });

        tmpYear -= 1;
    }

    return {
        // 更新时间 0当年 1去年 ... 5更早
        updateTime: [...tmpUpdateTime, { label: '更早', value: '5' }],
        // 文档页数范围,1为1-10；2为11-20；3为21-30；4为31-50；5为50以上
        filePageNum: [
            { value: '1', label: '1～10' },
            { value: '2', label: '11～20' },
            { value: '3', label: '21～30' },
            { value: '4', label: '31～50' },
            { value: '5', label: '50以上' },
        ],
        // 大小 1:0~5M 2:6~10M 3:11~20M 4:21~30M 5:31~50M 6:50M以上
        fileSize: [
            { value: '1', label: '0～5 M' },
            { value: '2', label: '6～10 M' },
            { value: '3', label: '11～20 M' },
            { value: '4', label: '21～30 M' },
            { value: '5', label: '31～50 M' },
            { value: '6', label: '50 M 以上' },
        ],
    };
}

export function getStaticMap() {
    const tmpMap = {
        // 开票状态
        invoiceTypeMap: {
            10: '未开票',
            20: '开票中',
            30: '开票失败',
            40: '已开票',
            50: '已寄出',
            60: '未确认',
            70: '已线下开票',
            80: '暂不可开',
        },

        // 订单状态 10-待支付 20-已完成(已支付/已付款) 30-待确认 40-已关闭 50-已确认 60-确认失败
        orderStatusMap: {
            10: '待支付',
            20: '已完成(已支付/已付款)',
            30: '待确认',
            40: '已关闭',
            50: '已确认',
            60: '确认失败',
        },

        // 订单类型 后台提供 1-会员服务 2-续费会员 4-数据导出
        // 订单类型 1-660元三年会员 2-330元一年会员 3-包季服务 4-一次性消费导出
        orderTypeMap: {
            1: '会员服务',
            2: '续费会员',
            3: '会员服务',
            4: '数据导出',
            5: '会员服务',
        },

        payTypeMap: {
            1: '微信',
            2: '支付宝',
        },

        // exportStatus	状态 1-生成中 2-已完成
        exportStatusMap: {
            1: '生成中',
            2: '已完成',
        },

        // exportType	导出类型 1-一次性购买导出 2-会员免费导出
        exportTypeMap: {
            1: '一次性购买导出',
            2: '会员免费导出',
        },
    };
    const tmpData = getStatic();

    Object.keys(tmpData).forEach(key => {
        tmpMap[key] = {};

        tmpData[key].forEach(item => {
            tmpMap[key][item.value] = item.label;
        });
    });

    return tmpMap;
}

// 支付套餐
export function getPayData(cb) {
    const { staticData } = store.getters;

    if (staticData.pay) {
        cb && cb(staticData.pay);

        return false;
    }

    // const tmpMockData = [
    //     {
    //         guid: '6bC9A666-f748-C4e1-f6A7-bcf5E79AACfb',
    //         vipAmount: '296',
    //         beofreAmount: '480',
    //         preview: '100',
    //         download: '20',
    //         vipDuration: 12,
    //         vipDesc: '每天仅需0.9元',
    //         defaultFlag: false,
    //         exportShow: true,
    //         angleMark: '',
    //         rankField: 1,
    //     },
    //     {
    //         guid: 'c4EA9D78-8e9b-01dc-Ccbf-eC66d24c88BF',
    //         vipAmount: '568',
    //         beofreAmount: '1440',
    //         preview: '120',
    //         download: '25',
    //         vipDuration: 24,
    //         vipDesc: '每天仅需0.6元',
    //         defaultFlag: false,
    //         exportShow: false,
    //         angleMark: '',
    //         rankField: 2,
    //     },
    //     {
    //         guid: 'cbb0211B-FBc5-df09-ea69-D8b7d2E8e339',
    //         vipAmount: '829',
    //         beofreAmount: '2400',
    //         preview: '150',
    //         download: '30',
    //         vipDuration: 36,
    //         vipDesc: '每天仅需0.5元',
    //         defaultFlag: true,
    //         exportShow: false,
    //         angleMark: '限时折扣',
    //         rankField: 3,
    //     },
    // ];

    // store
    //     .dispatch('SetStaticData', {
    //         key: 'pay',
    //         data: tmpMockData,
    //     })
    //     .then(() => {
    //         // console.log(tmpMockData);
    //         cb && cb(tmpMockData);
    //     })
    //     .catch(() => {});

    ApiOrderGetVipConfig()
        .then(res => {
            // console.log(JSON.stringify(res.data));

            store
                .dispatch('SetStaticData', {
                    key: 'pay',
                    data: res.data,
                })
                .then(() => {
                    // console.log(res.data);
                    cb && cb(res.data);
                })
                .catch(() => {});
        })
        .catch(() => {
            cb && cb();
        });
}

// 会员导出配置
export function getExportCfg(cb) {
    const { staticData } = store.getters;

    // console.log(staticData);

    if (staticData.export) {
        cb && cb(staticData.export);

        return false;
    }

    // ApiExportGetExportConfig()
    //     .then(res => {
    //         // console.log(res.data);
    //         const { key, value } = res.data;

    //         const tmpData = {
    //             // 每日导出次数
    //             exportTimes: key,
    //             // 每次导出条数
    //             exportNumber: value,
    //         };

    //         store
    //             .dispatch('SetStaticData', {
    //                 key: 'export',
    //                 data: tmpData,
    //             })
    //             .then(() => {
    //                 cb && cb(tmpData);
    //             })
    //             .catch(() => {});
    //     })
    //     .catch(() => {
    //         cb && cb();
    //     });
}
